<template>
  <q-list>
    <q-expansion-item
      expand-separator
      :label="$t('derby_parking.have_permit')"
    >
      <q-card>
        <q-card-section>
          {{ permit.time }}
          <q-list bordered separator class="rounded-borders q-my-md">
            <q-item v-for="price in permit.prices" :key="price.time" class="price-item">
              <span>{{ price.time }}</span>
              <span class="price text-primary">{{ price.price.toFormat('$0.00') }}</span>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-expansion-item>

    <q-expansion-item
      expand-separator
      :label="$t('derby_parking.no_permit')"
    >
      <q-card>
        <q-card-section>
          {{ noPermit.time }}
          <q-list bordered separator class="rounded-borders q-my-md">
            <q-item v-for="price in noPermit.prices" :key="price.time" class="price-item">
              <span>{{ price.time }}</span>
              <span class="price text-primary">{{ price.price.toFormat('$0.00') }}</span>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-expansion-item>

    <q-expansion-item
      expand-separator
      :label="$t('derby_parking.motorbike')"
    >
      <q-card>
        <q-card-section>
          {{ $t('derby_parking.motorbike_free') }}
          <br>
          <br>

          {{ $t('derby_parking.motorbike_no_permit') }}
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>
</template>

<script>

export default {
  name: 'ParkingPrices',
  props: {
    permit: {
      type: Object
    },
    noPermit: {
      type: Object
    },
    motorbike: {
      type: String
    }
  }

}
</script>

<style lang="stylus" scoped>
.price-item
  display flex
  justify-content space-between
  align-items center
</style>
