<template>
  <q-page class="q-pa-md">
    <div class="q-pa-lg row">
      <div class="col-2">
        <q-icon name="fas fa-parking" size="42px" color="cyan-5" />
      </div>
      <div class="col">
        <div>Kedleston Road Car Park</div>
        <div>University of Derby</div>
      </div>
    </div>
    <q-separator />
    <div class="q-pa-lg">
      <i18n path="derby_parking.permits_required">
        <a class="text-primary" href="https://link.mipermit.com/park?l=700180" target="_blank">{{ $t('derby_parking.here') }}</a>
      </i18n>
      <br>
      <br>
      {{ $t('derby_parking.see_pricing') }}
    </div>
    <q-separator />
    <parking-prices v-bind="parkingData" />
  </q-page>
</template>

<script>
import ParkingPrices from './ParkingPrices'
import Dinero from 'dinero.js'

export default {
  components: { ParkingPrices },
  data () {
    return {
      parkingData: {
        permit: {
          time: 'Monday - Friday 08:30 - 16:30',
          prices: [
            {
              time: 'Up to 1 hour',
              price: Dinero({ amount: 100, currency: 'GBP' })
            },
            {
              time: 'Up to 4 hours',
              price: Dinero({ amount: 270, currency: 'GBP' })
            },
            {
              time: 'Over 4 hours',
              price: Dinero({ amount: 440, currency: 'GBP' })
            }
          ]
        },
        noPermit: {
          time: 'Saturday - Sunday',
          prices: [
            {
              time: 'Up to 1 hour',
              price: Dinero({ amount: 200, currency: 'GBP' })
            },
            {
              time: 'Up to 4 hours',
              price: Dinero({ amount: 330, currency: 'GBP' })
            },
            {
              time: 'Over 4 hours',
              price: Dinero({ amount: 440, currency: 'GBP' })
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.permit-info
  color blue
</style>
